import React from "react"

import Layout from "components/Layout"
import {
  About,
  Content,
  Grid,
  Numbers,
  Convenience,
  Gallery,
} from "page_components/home"

const Home = () => (
  <Layout seo={{ title: "Strona główna" }} isHome>
    <About />
    <Content />
    <Grid />
    <Numbers />
    <Convenience />
    <Gallery />
  </Layout>
)

export default Home
