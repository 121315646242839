import "./styles.scss"

import React from "react"

const About = () => {
  return (
    <section className="home-about">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div className="home-about__image">
              <img
                src={require("assets/images/investment-hero-image.jpg").default}
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <h2>
              Nowoczesne apartamenty<span> w najbardziej prestiżowej</span>{" "}
              dzielnicy Krakowa
            </h2>
            <p>
              Mazowiecka 115 jest zlokalizowana w sercu malowniczej dzielnicy
              Krowodrza. Ta atrakcyjna lokalizacja oferuje doskonałe warunki dla
              tych, którzy pragną mieszkać w otoczeniu zabytkowej atmosfery
              miasta, nie rezygnując jednocześnie z kontaktu z naturą. Bliskość
              Rynku Głównego i centrum miasta sprawia, że jego bogata oferta
              kulturalna, gastronomiczna i rozrywkowa pozostaje łatwo dostępna
              dla mieszkańców.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
