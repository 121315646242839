import "./styles.scss"

import React from "react"
import Slider from "react-slick"

const images = [
  require("assets/images/convenients/slider/convenient-image-1.jpg").default,
  require("assets/images/convenients/slider/convenient-image-2.jpg").default,
  require("assets/images/convenients/slider/convenient-image-3.jpg").default,
  require("assets/images/convenients/slider/convenient-image-4.jpg").default,
  require("assets/images/convenients/slider/convenient-image-5.jpg").default,
  require("assets/images/convenients/slider/convenient-image-6.jpg").default,
]

const Content = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 750,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
  }

  return (
    <section className="home-content">
      <div className="container-fluid">
        <div className="row flex-lg-row-reverse">
          <div className="col-xl-7 col-lg-6">
            <Slider {...settings}>
              {images.map((item, index) => (
                <div key={index}>
                  <div
                    className="home-content__image"
                    style={{
                      backgroundImage: `url(${item})`,
                    }}
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="home-content__body">
              <div className="home-content__body-inner">
                <h2>STANDARD, KTÓREGO PRAGNIESZ!</h2>
                <div className="home-content__description">
                  <p>
                    Mazowiecka115 to nowoczesna inwestycja wyróżniająca się na
                    tle okolicznych zabudowań. Zachwycająca z zewnątrz
                    architektura jeszcze więcej kryje w swoim wnętrzu.
                    Znajdziesz tu miejsce do życia, pracy, ale też do rekreacji
                    i odpoczynku. Wyjątkowy <strong>pokój klubowy</strong> to
                    przestrzeń, którą możesz wykorzystać do pracy w
                    sprzyjających warunkach. Obok coworkingu jest doskonały do
                    spotkań towarzyskich lub odpoczynku. Wspólne kibicowanie,
                    oglądanie filmów, długie rozmowy - pięknie urządzone
                    pomieszczenie daje Ci wiele opcji wykorzystania! Doskonale
                    zaaranżowana <strong>strefa relaksu</strong> to natomiast
                    szansa na odzyskanie równowagi po intensywnym dniu.
                    Znajdziesz tu miejsce na medytację lub jogę, a pełen
                    chillout zagwarantuje Ci ściana solna, a nawet tężnia
                    solankowa! Bliskość natury zapewnia{" "}
                    <strong>prywatny ogród</strong> wspólny dostępny wyłącznie
                    dla mieszkańców, a o Twoją prywatność dba{" "}
                    <strong>całodobowa recepcja</strong> czynna 7 dni w
                    tygodniu.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content
