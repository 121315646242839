import "./styles.scss"

import React from "react"

const Numbers = () => {
  return (
    <section className="home-numbers">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 hidden-md">
            <div className="home-numbers__item">
              <span>86</span>
              <p>mieszkań o różnej powierzchni</p>
            </div>
          </div>

          <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-1">
            <div className="home-numbers__baner">
              <h2>Balkon, taras, a może ogród?</h2>
              <p>
                Wszystkie mieszkania posiadają dostęp do balkonu, tarasu lub
                ogrodu. Wybierz najlepiej dopasowany do Twoich potrzeb wariant.
                Mieszkania zlokalizowane na parterze posiadają ogrody, te na
                najwyższych kondygnacjach tarasy, a pozostałe wyposażone są w
                wygodne balkony.
              </p>
            </div>
          </div>

          <div className="col-md-3 col-sm-4 col-6 hidden-lg">
            <div className="home-numbers__item">
              <span>86</span>
              <p>mieszkań o różnej powierzchni</p>
            </div>
          </div>

          <div className="col-md-3 col-sm-4 col-6">
            <div className="home-numbers__item">
              <span>47</span>
              <p>miejsc postojowych</p>
            </div>
          </div>

          <div className="col-md-3 col-sm-4 col-6">
            <div className="home-numbers__item">
              <span>5</span>
              <p>pięter + poddasze</p>
            </div>
          </div>

          <div className="col-md-3 col-sm-4 col-6">
            <div className="home-numbers__item">
              <span>8</span>
              <p>mieszkań dwupoziomowych</p>
            </div>
          </div>

          <div className="col-md-3 col-sm-4 col-6">
            <div className="home-numbers__item">
              <span>2</span>
              <p>osobne klatki schodowe</p>
            </div>
          </div>

          <div className="col-md-3 col-sm-4 col-6 offset-lg-3">
            <div className="home-numbers__item">
              <span>5</span>
              <p>stanowisk ładowania pojazdów elektrycznych</p>
            </div>
          </div>

          <div className="col-md-3 col-sm-4 col-6">
            <div className="home-numbers__item">
              <span>9</span>
              <p>lokali z tarasami</p>
            </div>
          </div>

          <div className="col-md-3 col-sm-4 col-6">
            <div className="home-numbers__item">
              <span>2</span>
              <p>windy</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Numbers
